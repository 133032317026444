var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app-container proto-list"},[(_vm.isLoadTopicDataFinished)?_c('baseTable',{ref:"table",attrs:{"checkAll":"","list":_vm.finalList,"handleSearchFormProps":_vm.handleSearchFormProps},scopedSlots:_vm._u([{key:"menuLeft",fn:function(){return [(_vm.permission.addTopic)?_c('baseButton',{attrs:{"plain":"","size":"small"},on:{"click":function($event){return _vm.addOrDelTopic('add')}}},[_vm._v(" 加入专题 ")]):_vm._e(),(_vm.permission.batchTopic)?_c('baseButton',{attrs:{"plain":"","size":"small"},on:{"click":function($event){return _vm.addOrDelTopic('del')}}},[_vm._v(" 批量移除 ")]):_vm._e()]},proxy:true},{key:"platformCategoryIdSearchForm",fn:function(ref){
var form = ref.form;
var prop = ref.prop;
var placeholder = ref.placeholder;
return [_c('ProtoTypeTreeSelect',{staticStyle:{"width":"100%"},attrs:{"size":"mini","placeholder":placeholder,"selectedValue":form[prop],"maxHeight":150},on:{"update:selectedValue":function($event){return _vm.$set(form, prop, $event)},"update:selected-value":function($event){return _vm.$set(form, prop, $event)},"change":_vm.searchChange}})]}},{key:"chineseName",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"flex-center"},[_c('base-image',{staticStyle:{"width":"60px","height":"60px"},attrs:{"src":_vm.figure(row),"size":"100"}}),_c('div',[_c('div',{staticStyle:{"max-width":"200px"}},[_vm._v(" "+_vm._s(row.chineseName || '暂无名字')+" ")])])],1)]}},{key:"style",fn:function(ref){
var row = ref.row;
return _vm._l((_vm.styleList(row) || []),function(item,index){return _c('el-tag',{key:index,staticClass:"tag-medium",attrs:{"type":"primary"}},[_vm._v(" "+_vm._s(item.name)+" ")])})}},{key:"menu",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [(_vm.permission.add && row.goodsStatus == _vm.PRO_ADD)?_c('addOrDel',{attrs:{"rowIndex":$index,"type":"add","data":row},on:{"addHandler":function($event){return _vm.addHandler(row)}}}):_vm._e(),(_vm.permission.remove && row.goodsStatus == _vm.PRO_REMOVE)?_c('addOrDel',{attrs:{"rowIndex":$index,"type":"remove","data":row},on:{"delHandler":function($event){return _vm.delHandler(row)}}}):_vm._e()]}}],null,false,3474827532)}):_vm._e(),_c('div',{staticClass:"flex-middle-center"},[(_vm.permission.back)?_c('el-button',{on:{"click":_vm.back}},[_vm._v("返回")]):_vm._e(),(_vm.permission.submit)?_c('loading-btn',{attrs:{"type":"primary"},on:{"click":_vm.doSubmit}},[_vm._v("保存")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }