<template>
  <div class="app-container proto-list">
    <baseTable
      v-if="isLoadTopicDataFinished"
      ref="table"
      checkAll
      :list="finalList"
      :handleSearchFormProps="handleSearchFormProps"
    >
      <template #menuLeft>
        <!-- 加入专题 -->
        <baseButton v-if="permission.addTopic" plain size="small" @click="addOrDelTopic('add')">
          加入专题
        </baseButton>
        <baseButton v-if="permission.batchTopic" plain size="small" @click="addOrDelTopic('del')">
          批量移除
        </baseButton>
      </template>

      <template #platformCategoryIdSearchForm="{form, prop, placeholder}">
        <ProtoTypeTreeSelect
          size="mini"
          style="width: 100%"
          :placeholder="placeholder"
          :selectedValue.sync="form[prop]"
          :maxHeight="150"
          @change="searchChange"
        />
      </template>

      <template #chineseName="{ row }">
        <div class="flex-center">
          <base-image :src="figure(row)" size="100" style="width: 60px; height: 60px"></base-image>
          <div>
            <div style="max-width: 200px">
              {{ row.chineseName || '暂无名字' }}
            </div>
          </div>
        </div>
      </template>

      <template #style="{ row }">
        <el-tag class="tag-medium" type="primary" :key="index" v-for="(item,index) in styleList(row) || []">
          {{ item.name }}
        </el-tag>
      </template>

      <template #menu="{ row, $index }">
        <!-- 添加 -->
        <addOrDel
          v-if="permission.add && row.goodsStatus == PRO_ADD"
          :rowIndex="$index"
          type="add"
          :data="row"
          @addHandler="addHandler(row)"
        />
        <!-- 移除 -->
        <addOrDel
          v-if="permission.remove && row.goodsStatus == PRO_REMOVE"
          :rowIndex="$index"
          type="remove"
          :data="row"
          @delHandler="delHandler(row)"
        />
      </template>
    </baseTable>
    <div class="flex-middle-center">
      <el-button v-if="permission.back" @click="back">返回</el-button>
      <loading-btn v-if="permission.submit" type="primary" @click="doSubmit">保存</loading-btn>
    </div>
  </div>
</template>

<script>
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import addOrDel from './module/addOrDel'
import baseTableMixin from '@/components/base/baseTable/mixins/baseTable'
import getBaseTableDataMixin from '@/components/base/baseTable/mixins/getBaseTableDataMixin'
import recommendTopicsSitApi from '@/api/recommendTopicsSitApi'
import { list } from './const'
import { map } from 'lodash'
import { getPrototypeList } from '@/api/product/protoApi'
import { REQUEST_ALL_DATA } from '@/utils/constant'
import { getValueFromObj, waitTimeByNum } from '@/utils'
import { PRO_ADD, PRO_REMOVE } from '@/utils/constant'

export default {
  name: 'addProduct',

  components: {
    ProtoTypeTreeSelect,
    addOrDel
  },

  mixins: [
    baseTableMixin,
    getBaseTableDataMixin({
      dataAttrs: {
        tableData: [],
        postData: {}
      }
    })
  ],

  data() {
    return {
      PRO_ADD,
      PRO_REMOVE,
      topicData: [],
      isLoadTopicDataFinished: false
    }
  },

  created() {
    this.initData()
  },

  computed: {
    styleList() {
      return (row) => {
        return $GET(row, 'styleList', [])
      }
    },

    figure() {
      return (row) => getValueFromObj(row, 'styleList[0].styleDisplayImageList[0].displayImagePath')
    },

    isTopic({ curTabItem }) {
      return curTabItem.value == 0
    },

    finalList() {
      return {
        column: list.call(this).column,
        defaultValue: this.$route.query.value,
      }
      
    },

    marketingCategoryId({ $route }) {
      return $GET($route, 'query.id', null)
    },

    handleSearchFormProps() {
      return {
        styleNameLikeList(value) {
          return value.split(/\n|' '|,|，/g).filter(Boolean)
        }
      }
    }
  },

  methods: {
    getValueFromObj,

    async initData() {
      const res = await awaitResolveDetailLoading(
        getPrototypeList({
          ...REQUEST_ALL_DATA,
          marketingCategoryId: this.$route.query.id
        })
      )
      this.topicData = (res || []).map(item => {
        item.goodsStatus = PRO_REMOVE
        return item
      })
      //请求完成，渲染baseTable
      this.isLoadTopicDataFinished = true
    },

    // 批量加入/移除专题
    async addOrDelTopic(type) {
      let selectData = await this.getSelectionData()
      if (!selectData.length) return
      if (type == 'add') {
        selectData = selectData.filter(item => item.goodsStatus == PRO_ADD)
      }
      selectData.map((item) => (type == 'add' ? this.addHandler(item) : this.delHandler(item)))
    },

    // 移除
    delHandler(data) {
      this.curTabItem.delHandler.call(this, data)
    },

    // 添加
    addHandler(data) {
      this.curTabItem.addHandler.call(this, data)
    },

    // 保存
    async doSubmit() {
      if (!this.topicData.length) return this.$message.warning('请添加至少一个产品')
      const prototypeIdList = map(this.topicData, 'id')
      const res = await awaitResolveDetailLoading(
        recommendTopicsSitApi.batchSet({
          prototypeIdList,
          categoryId: this.marketingCategoryId
        })
      )
      if (!res) {
        return
      }
      this.$message.success('操作成功')
      await waitTimeByNum(200)
      this.back()
    },

    back() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .app-container {
    display: flex;
    flex-direction: column;
  }

  .flex-one-page {
    height: 90%;
  }
}

.flex-middle-center {
  margin-top: 15px;
  width: 100%;
  height: 60px;
  bottom: 5px;
  border: 1px solid #ebebeb;
  flex: 0 0 60px;
}
</style>
